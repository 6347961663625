import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';

export default function CustomDialog({ open, title, displayText, isConfirmationModalLoading, handleModalClose, handleSave }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <React.Fragment>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleModalClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {displayText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <LoadingButton variant="contained" onClick={handleSave} loading={isConfirmationModalLoading} autoFocus>
                    {/* //<Button variant="contained" onClick={handleSave} autoFocus> */}
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}