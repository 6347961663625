import { useState } from 'react';
import axios from 'axios';
import { Box, Tab, Tabs, Grid, TextField, Button } from "@mui/material";
import ActionButtons from '../ActionButtons/ActionButtons';
import FilterFields from '../FilterFields/FilterFields';
import Summary from '../Summary/Summary';
import DropDownComponent from '../../common/DropDownComponent/DropDownComponent';
import DataGridComponent from '../../common/DataGridComponent/DataGridComponent';
import DialogComponent from '../../common/DialogComponent/DialogComponent';
import DateTimePickerComponent from '../../common/DateTimePickerComponent/DateTimePickerComponent';
import Spinner from '../../common/Spinner/Spinner';
import { config } from '../../common/util/config';
import { useRoles } from '../../contexts/TokenContext/AuthenticatedEmployeeWrapper';
import { useToken } from "../../contexts/TokenContext/AuthenticatedEmployeeWrapper";
import { getColumns, statusOptions, deepEqualObjects, getRequestHeaders } from "../../utils";
import "./Home.css";


export default function Home() {
    const roles = useRoles(); //import useRoles method and check if it is calling an api to fetch roles, if api is calling don't use this instead use below line

    //console.log('rolessss in home', roles)
    const [currentTabIndex, setCurrentTabIndex] = useState("one");
    const [openEdit, setOpenEdit] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [OriginalTableData, setOriginalTableData] = useState([]);
    const defaultSelectedRow = { BusinessApproval: "" };
    const [originalSelectedEditRow, setOriginalSelectedEditRow] = useState(defaultSelectedRow);
    const [selectedEditRow, setSelectedEditRow] = useState(defaultSelectedRow);
    const [selectedRows, setSelectedRows] = useState([]);


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);
    const [filterFields, setFilterFields] = useState({ Premise: "", CreditType: "" });
    const [token, usrName] = useToken();

    const handleFilterFieldChange = (e) => {
        //console.log('handleFilterFieldChange', e.target.id, e.target.value);
        setFilterFields({ ...filterFields, [e.target.id]: e.target.value })
    };
    const [paginationModel, setPaginationModel] = useState({ pageSize: 100, page: 0, });
    const handlePaginationModel = (a) => {
        setPaginationModel(a);
    }

    const handleAction = (e, row) => {
        setOpenEdit(true);
        setSelectedEditRow({ ...row });
        setOriginalSelectedEditRow({ ...row });
    };
    let columns = [];
    const pageType = currentTabIndex === "two" ? "withOutStatus" : currentTabIndex === "three" ? "withStatus" : null;
    if (pageType) {
        columns = getColumns(handleAction, pageType, roles, currentTabIndex);
    }
    const disableActionButtons = currentTabIndex === "two" ? false : true;

    const getStatusData = async (type) => {
        setLoading(true);
        let url = "";
        if (type && type === "withOutStatus") {
            url = `${config.apiBase}/GetCreditSummary?isReviewStatus=false`;
        } else if (type && type === "withStatus") {
            url = `${config.apiBase}/GetCreditSummary?isReviewStatus=true`;
        }
        try {
            const response = await axios.get(url
                , {
                    headers: getRequestHeaders(token)
                }
            );
            // Handle successful response
            setTableData(response.data);
            setOriginalTableData(response.data);
            setSelectedRows([]);
            setPaginationModel({ pageSize: 100, page: 0, });
            setLoading(false);
        } catch (error) {
            // Handle error
            setLoading(false);
        }
    }

    const getTableData = async (type) => {
        if (hasFilterValues()) {
            handleNewFilterDateRange();
        }
        else {
            getStatusData(type);
        }

    };

    const actionDialogTitle = currentTabIndex === "two" ? "Edit" : "View";

    const handleTabChange = (e, tabIndex) => {
        setStartDateTime(null);
        setEndDateTime(null);
        setFilterFields({ Premise: '', CreditType: '' });
        setCurrentTabIndex(tabIndex);

        let type = null;

        if (tabIndex === "two") {
            type = "withOutStatus";

        } else if (tabIndex === "three") {
            type = "withStatus";
        }
        if (type) {
            getTableData(type);
        }
    };

    const handleClose = () => {
        setOpenEdit(false);
        setOriginalSelectedEditRow(defaultSelectedRow);
        setSelectedEditRow(defaultSelectedRow);
    }

    const handleSave = async () => {
        const isEqual = deepEqualObjects(originalSelectedEditRow, selectedEditRow);
        if (!isEqual) {
            //alert("make api");
            const payload = { ...selectedEditRow };
            try {
                setLoading(true);
                //Add actual api url for saving edit data
                //console.log('Here is the values for api call', payload.BusinessApproval, payload.UniqueId);
                const postERC = {
                    "UniqueId": payload.UniqueId,
                    "BusinessApproval": payload.BusinessApproval,
                    "BusinessApprovalBy": usrName
                }
                //console.log('postERC', postERC);
                const response = await axios.post(`${config.apiBase}/PostCreditSummary`, postERC
                    , {
                        headers: getRequestHeaders(token, 'post')
                    }
                );
                // Make a GET request to retrieve the updated data
                //console.log('Response for Posting Approved or Denied', response);

                setOriginalSelectedEditRow(defaultSelectedRow);
                setSelectedEditRow(defaultSelectedRow);
                setSelectedRows([]);
                setPaginationModel({ pageSize: 100, page: 0, });
                try {
                    //console.log('entered refresh get');
                    const getResponse = await axios.get(`${config.apiBase}/GetCreditSummary?isReviewStatus=false`,
                        {
                            headers: getRequestHeaders(token)
                        });
                    setTableData(getResponse.data);
                } catch (error) {
                    console.error('Error retrieving updated data:', error);
                }
                setOpenEdit(false);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        } else {
            alert("You did not edit any changes.Please change BusinessApproval to edit.")
        }
    }
    const handleChange = (e) => {
        const { id, value } = e.target;
        setSelectedEditRow({ ...selectedEditRow, [id]: value });
    }
    const getSlicedData = (pageNumber, pageSize, selection) => {        
        //const startIndex = Math.max((pageNumber - 1) * pageSize, 0);        
        //const endIndex = startIndex + pageSize;
        const startIndex = pageNumber * pageSize;
        const endIndex = (pageNumber + 1) * pageSize;
        //console.log(OriginalTableData, selection);
        // reorder the selected rows based on the order of sorted data
        const selectedData = selection.map(id => OriginalTableData.find(item => item.UniqueId === id));
        //return OriginalTableData.slice(startIndex, endIndex);
        return [...selectedData].slice(startIndex, endIndex);
    }
    const hasFilterValues = () => {
        const { Premise, CreditType } = filterFields;
        return startDateTime || endDateTime || Premise || CreditType;
    }
    const handleSelectionChange = (selection) => {        
        if (paginationModel && selection && selection.length && selection.length === tableData.length) {
            const currentPageSelectedRows = getSlicedData(paginationModel.page, paginationModel.pageSize, selection);
            const selectedRows = currentPageSelectedRows.map((rowObj) => rowObj.UniqueId);            
            setSelectedRows([...selectedRows]);
        } else {            
            setSelectedRows([...selection]);
        }
    }

    const handleDropDownChange = (key, value) => {
        setSelectedEditRow({ ...selectedEditRow, [key]: value });
    }

    const editFields = [
        {
            id: "UniqueId",
            label: "UniqueId",
            value: selectedEditRow?.UniqueId,
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "OutageUniqueID",
            label: "OutageUniqueID",
            value: selectedEditRow?.OutageUniqueID,
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "Premise",
            label: "Premise",
            value: selectedEditRow?.Premise,
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "ContractAmount",
            label: "ContractAmount",
            value: selectedEditRow?.ContractAmount || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "OutageDate",
            label: "OutageDate",
            value: selectedEditRow?.OutageDate || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "RestorationDate",
            label: "RestorationDate",
            value: selectedEditRow?.RestorationDate || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "DurationHrs",
            label: "DurationHrs",
            value: selectedEditRow?.DurationHrs || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "WeatherCondition",
            label: "WeatherCondition",
            value: selectedEditRow?.WeatherCondition || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "CustomerName",
            label: "CustomerName",
            value: selectedEditRow?.CustomerName || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "HouseNumber",
            label: "HouseNumber",
            value: selectedEditRow?.HouseNumber || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "Street",
            label: "Street",
            value: selectedEditRow?.Street || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "AptBuilding",
            label: "AptBuilding",
            value: selectedEditRow?.AptBuilding || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "AptBuildingNumber",
            label: "AptBuildingNumber",
            value: selectedEditRow?.AptBuildingNumber || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "City",
            label: "City",
            value: selectedEditRow?.City || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "State",
            label: "State",
            value: selectedEditRow?.State || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "ZipCode",
            label: "ZipCode",
            value: selectedEditRow?.ZipCode || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "Cause",
            label: "Cause",
            value: selectedEditRow?.Cause || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "CreditDollar",
            label: "CreditDollar",
            value: selectedEditRow?.CreditDollar || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "CreditType",
            label: "CreditType",
            value: selectedEditRow?.CreditType || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "ContractAccountStatus",
            label: "ContractAccountStatus",
            value: selectedEditRow?.ContractAccountStatus || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "FrequencyOutageCounter",
            label: "FrequencyOutageCounter",
            value: selectedEditRow?.FrequencyOutageCounter || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "CustomerCount",
            label: "CustomerCount",
            value: selectedEditRow?.CustomerCount || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "MeterType",
            label: "MeterType",
            value: selectedEditRow?.MeterType || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "PreviousAmtPaidFREQ",
            label: "PreviousAmtPaidFREQ",
            value: selectedEditRow?.PreviousAmtPaidFREQ || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "PreviousPostedDateFREQ",
            label: "PreviousPostedDateFREQ",
            value: selectedEditRow?.PreviousPostedDateFREQ || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "ContractAccountType",
            label: "ContractAccountType",
            value: selectedEditRow?.ContractAccountType || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "InstallationNumber",
            label: "InstallationNumber",
            value: selectedEditRow?.InstallationNumber || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "MeterNumber",
            label: "MeterNumber",
            value: selectedEditRow?.MeterNumber || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "AmountCredited",
            label: "AmountCredited",
            value: selectedEditRow?.AmountCredited || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "RateCategory",
            label: "RateCategory",
            value: selectedEditRow?.RateCategory || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "BusinessApproval",
            label: "BusinessApproval",
            value: selectedEditRow?.BusinessApproval,
            type: 'dropdown',
            options: statusOptions,
            onChange: handleDropDownChange,
            disabled: currentTabIndex === "two" ? false : true
        },
        {
            id: "BusinessApprovalDate",
            label: "BusinessApprovalDate",
            value: selectedEditRow?.BusinessApprovalDate || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "BusinessApprovalBy",
            label: "BusinessApprovalBy",
            value: selectedEditRow?.BusinessApprovalBy || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "FileGeneratedforBOTDate",
            label: "FileGeneratedforBOTDate",
            value: selectedEditRow?.FileGeneratedforBOTDate || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "ArchiveDate",
            label: "ArchiveDate",
            value: selectedEditRow?.ArchiveDate || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "EDA_Creation_Date",
            label: "EDA_Creation_Date",
            value: selectedEditRow?.EDA_Creation_Date || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        },
        {
            id: "EDA_Modification_Date",
            label: "EDA_Modification_Date",
            value: selectedEditRow?.EDA_Modification_Date || "",
            type: 'text',
            onChange: handleChange,
            disabled: true
        }
    ]

    function convertToEST(dateString) {
        const date = new Date(dateString);
        // Check if the provided date is already in Eastern Standard Time (EST)
        if (date.getTimezoneOffset() === -300) { // EST timezone offset is -300 minutes (-5 hours)
            return dateString; // Return the same value if already in EST
        } else {
            // Format the date in EST
            const estFormat = date.toLocaleString('en-US', { timeZone: 'America/New_York' });
            return estFormat;
        }
    }

    function convertDateFormat(dateVal) {
        var dat = dateVal;
        var estdate = dat.split("/").reverse();
        var tmp = estdate[2];
        estdate[2] = estdate[1];
        estdate[1] = tmp;
        if (estdate[2].length === 1) {
            estdate[2] = '0' + estdate[2];
        }

        if (estdate[1].length === 1) {
            estdate[1] = '0' + estdate[1];
        }
        estdate = estdate.join("-");

        return estdate;
    }

    function convertTimeFormat(timeStr) {

        const [time, modifier] = timeStr.split(' ');
        console.log('time', time);
        console.log('modifier', modifier);
        let [hours, minutes, seconds] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = (parseInt(hours, 10) + 12).toString();
        }
        if (hours.length === 1) {
            hours = '0' + hours;
        }
        return `${hours}:${minutes}:00.000`;
    }

    const handleNewFilterDateRange = () => {

        const { Premise, CreditType } = filterFields;

        if (startDateTime && endDateTime && !Premise && !CreditType) {
            // const estStartDateTime = convertToEST(startDateTime);
            // const estEndDateTime = convertToEST(endDateTime);

            // const startDate = new Date(estStartDateTime).toLocaleString();

            // const endDate = estEndDateTime.toLocaleString();
            const [startDate, endDate] = getDateConversionVals(startDateTime, endDateTime);
            getDataWithDate(convertDateFormat(startDate.split(',')[0]) + 'T' + convertTimeFormat(startDate.split(',')[1].trim()), convertDateFormat(endDate.split(',')[0]) + 'T' + convertTimeFormat(endDate.split(',')[1].trim()), undefined, undefined);

        }

        if (!startDateTime && !endDateTime && (Premise || CreditType)) {
            getPremiseOrCreditType(Premise, CreditType);
        }

        if (startDateTime && endDateTime && (Premise || CreditType)) {
            const [startDate, endDate] = getDateConversionVals(startDateTime, endDateTime);
            getDataWithDate(convertDateFormat(startDate.split(',')[0]) + 'T' + convertTimeFormat(startDate.split(',')[1].trim()), convertDateFormat(endDate.split(',')[0]) + 'T' + convertTimeFormat(endDate.split(',')[1].trim()), Premise, CreditType);
        }

    }

    function getDateConversionVals(outageStart, outageEnd) {
        const estStartDateTime = convertToEST(outageStart);
        const estEndDateTime = convertToEST(outageEnd);

        const startDate = new Date(estStartDateTime).toLocaleString();

        const endDate = estEndDateTime.toLocaleString();

        return [startDate, endDate];
    }

    const getPremiseOrCreditType = async (premise, creditType) => {
        let url = '';
        let status = 'false';
        if (currentTabIndex && currentTabIndex === "three") {
            status = 'true';
        }
        //let queryParams = `${config.apiBase}`;
        if (premise && creditType) {
            url = `${config.apiBase}/GetCreditSummary?isReviewStatus=${status}&premise=${premise}&creditType=${creditType}`;
        }

        if (premise && !creditType) {
            url = `${config.apiBase}/GetCreditSummary?isReviewStatus=${status}&premise=${premise}`;
        }

        if (!premise && creditType) {
            url = `${config.apiBase}/GetCreditSummary?isReviewStatus=${status}&creditType=${creditType}`;
        }

        try {
            setLoading(true);
            const response = await axios.get(url
                , {
                    headers: getRequestHeaders(token)
                }
            );
            setLoading(false);
            setTableData(response.data);
            setOriginalTableData(response.data);
            setSelectedRows([]);
            setPaginationModel({ pageSize: 100, page: 0, });
        } catch (error) {
            // Handle error
            setLoading(false);
            console.error('Error fetching getPremiseOrCreditType data:', error);
        }
    }
    const getDataWithDate = async (startDate, endDate, premise, creditType) => {
        let makeApi = false;
        let url = "";
        if (currentTabIndex && currentTabIndex === "two") {
            url = `${config.apiBase}/GetCreditSummaryByDates?isReviewStatus=false&startDate=${startDate}&endDate=${endDate}`;
            makeApi = true;
        } else if (currentTabIndex && currentTabIndex === "three") {
            url = `${config.apiBase}/GetCreditSummaryByDates?isReviewStatus=true&startDate=${startDate}&endDate=${endDate}`;
            makeApi = true;
        }
        if (premise) {
            url = url + `&premise=${premise}`
        }
        if (creditType) {
            url = url + `&creditType=${creditType}`
        }
        if (makeApi) {

            try {
                setLoading(true);
                const response = await axios.get(url
                    , {
                        headers: getRequestHeaders(token)

                    }
                );
                setLoading(false);
                setTableData(response.data);
                setOriginalTableData(response.data);
                setSelectedRows([]);
                setPaginationModel({ pageSize: 100, page: 0, });
            } catch (error) {
                // Handle error
                setLoading(false);
                console.error('Error fetching getDataWithDate data:', error);
            }
        }
    }

    const handleStartDateTimeChange = (dateTime) => {
        setStartDateTime(dateTime);
    };
    const handleEndDateTimeChange = (dateTime) => {
        setEndDateTime(dateTime);
    };

    const handleReset = () => {
        setFilterFields({ Premise: "", CreditType: "" });
        setEndDateTime(null);
        setStartDateTime(null);
        let type = null;

        if (currentTabIndex === "two") {
            type = "withOutStatus";

        } else if (currentTabIndex === "three") {
            type = "withStatus";
        }
        getStatusData(type);
        setPaginationModel({ pageSize: 100, page: 0, });

    }
    const renderDataGrid = () => {
        const checkboxSelection = currentTabIndex === "two" && roles && roles.includes("user.admin");
        //For Local Testing comment above an uncomment below
        //const checkboxSelection = true;
        return (
            <Box sx={{ margin: "1% 0%", paddingLeft: "1%" }} className="home-wrapper">
                <Box sx={{ display: "flex", marginBottom: "1%" }}>
                    <DateTimePickerComponent
                        startDateTime={startDateTime}
                        endDateTime={endDateTime}
                        handleStartDateTimeChange={handleStartDateTimeChange}
                        handleEndDateTimeChange={handleEndDateTimeChange}
                    // handleNewFilterDateRange={handleNewFilterDateRange}
                    />
                    <FilterFields filterFields={filterFields} handleFilterFieldChange={handleFilterFieldChange} />
                    <Box sx={{ margin: "1% 0%", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "1%", paddingTop: "0.6%" }}>
                        <Button className='dateTimeFilter' autoFocus id="dateFilter" variant="outlined" onClick={handleNewFilterDateRange}>
                            Filter
                        </Button>
                    </Box>
                    <Box sx={{ margin: "1% 0%", display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "1%", paddingTop: "0.6%" }}>
                        <Button className='dateTimeFilter' autoFocus id="reset" variant="outlined" onClick={handleReset}>
                            Reset
                        </Button>
                    </Box>
                </Box>
                {loading ? <Spinner /> : <DataGridComponent
                    disableColumnMenu={true}
                    paginationModel={paginationModel}
                    handlePaginationModel={handlePaginationModel}
                    rows={tableData} columns={columns} getRowId={(row) => row.UniqueId} checkboxSelection={checkboxSelection}
                    disableRowSelectionOnClick={false} rowSelectionModel={[1, 3]} handleSelectionChange={handleSelectionChange} />
                }
            </Box>
        )
    }

    return (
        <Box className="home-wrapper" sx={{ p: 3 }}>
            <Box sx={{ width: '100%' }}>
                <Tabs
                    value={currentTabIndex}
                    onChange={handleTabChange}
                    aria-label="wrapped label tabs example"
                >
                    <Tab value="one" label="Summary" />
                    <Tab
                        value="two"
                        label="In-progress"
                    />
                    <Tab value="three" label="Approve/ Deny" />
                </Tabs>
                {loading ? <Spinner /> : currentTabIndex === "one" ?
                    <Summary tableData={tableData} /> : currentTabIndex === "two" ?
                        <>
                            <ActionButtons tableData={tableData} selectedRows={selectedRows} getTableData={getTableData} />
                            <>
                                {renderDataGrid()} </>
                        </> : renderDataGrid()
                }
            </Box>
            {openEdit && <DialogComponent disableActionButtons={disableActionButtons} open={openEdit} title={actionDialogTitle} handleClose={handleClose} handleSave={handleSave} loading={loading}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Grid container>
                        {editFields.map((field) => {
                            if (field?.type === "text") {
                                return (
                                    <Grid item xs={12}>
                                        <TextField
                                            required={field?.required}
                                            id={field?.id}
                                            label={field?.label}
                                            fullWidth
                                            value={field?.value}
                                            defaultValue={""}
                                            onChange={field?.onChange}
                                            disabled={field?.disabled}
                                        />
                                    </Grid>
                                )
                            } else if (field?.type === "dropdown") {
                                return (
                                    <Grid item xs={12}>
                                        <DropDownComponent
                                            required={field?.required}
                                            id={field?.id}
                                            label={field?.label}
                                            labelId={field?.labelId}
                                            options={field?.options}
                                            fullWidth
                                            value={field?.value}
                                            onChange={field?.onChange}
                                            disabled={field?.disabled}
                                            helperText={""}
                                        />
                                    </Grid>
                                )
                            }
                        })}
                    </Grid>
                </Box>
            </DialogComponent>}
        </Box>
    );
}
