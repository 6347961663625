export const apiPaths = new Map([])

if (process.env.REACT_APP_ENV !== "prod") {
  //console.log("environment: ", process.env.REACT_APP_ENV)
}

var Environments

;(function(Environments) {
  Environments[(Environments["PROD"] = 0)] = "PROD"
  Environments[(Environments["DEV"] = 1)] = "DEV"
  Environments[(Environments["TEST"] = 2)] = "TEST"
  Environments[(Environments["LAB"] = 3)] = "LAB"
  Environments[(Environments["LOCAL"] = 4)] = "LOCAL"
  Environments[(Environments["STORAGE"] = 5)] = "STORAGE"
})(Environments || (Environments = {}))

const apiBase = {}
apiBase[Environments.PROD] =
  "https://api.eda.sites.dteco.com/internal/prod/erc"
  
apiBase[Environments.TEST] =
  "https://api.test.eda.sites.dteco.com/internal/test/erc"

apiBase[Environments.DEV] =
  "https://api.test.eda.sites.dteco.com/internal/dev/erc"

apiBase[Environments.LAB] =
  "https://dte-e2-dev-eda-labs-apim.azure-api.net/internal/lab/erc"

apiBase[Environments.LOCAL] =
  "https://dte-e2-dev-eda-labs-apim.azure-api.net/internal/lab/erc"

  apiBase[Environments.STORAGE] =
  "https://dte-e2-dev-eda-labs-apim.azure-api.net/internal/lab/erc"

const webHost = {}
webHost["dev.erc.sites.dteco.com"] = Environments.DEV
webHost["test.erc.sites.dteco.com"] = Environments.TEST
webHost["erc.sites.dteco.com"] = Environments.PROD
webHost["lab.erc.sites.dteco.com"] = Environments.LAB
webHost["lab.erc.sites.dteco.com"] = Environments.LOCAL
webHost["dteedalabercstorage.z20.web.core.windows.net"] = Environments.STORAGE

class Config {
  // Default to prod values
  apiHost = apiBase[Environments.PROD]
  webEnv = undefined

  constructor() {
    const webEnv = webHost[window?.location?.hostname]
    this.webEnv = webEnv

    if (webEnv !== Environments.PROD) {
      // See if we have a local override
      this.apiHost = process.env.REACT_APP_API_BASE || apiBase[webEnv]
    }
  }

  get isProd() {
    return this.webEnv === Environments.PROD
  }

  get isDev() {
    return this.webEnv === Environments.DEV
  }

  get isLab() {
    return this.webEnv === Environments.LAB
  }

  get isTest() {
    return this.webEnv === Environments.TEST
  }

  get isLocal() {
    return this.webEnv === Environments.LOCAL
  }

  get isStorage() {
    return this.webEnv === Environments.STORAGE
  }
  
  get apiSubscriptionKey() {
    if (this.isProd) {
      return 'b92a89a039704665abe6873b3c2b5f89';
    }
    if (this.isLab || this.isLocal || this.isStorage) {
      return "cf48756e75ef40dca91517ede436f578";
    }
    if (this.isDev) {
      return "5ee9b8cf822d42cf879ec0196cba877a";
    }
    if (this.isTest) {
      return "19344dbf962042a0890e846b9057e3ba";
    }
    return window.localStorage.getItem('subscriptionKey') || '';
  }

  get apiBase() {
    //console.log('cameeeee',this.apiHost)

    return this.apiHost
  }

  // get dteHomepage(): string {
  //     return 'https://www.dteenergy.com';
  // }
}

export const config = new Config()
