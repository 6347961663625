import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './common/Header/Header';
import Home from './components/Home/Home';
import { AuthenticatedEmployeeWrapper, useRoles } from './contexts/TokenContext/AuthenticatedEmployeeWrapper';
//import { AppContext } from './contexts/AppContext';
/*function App() {

  return (
    <div className="App">
      <Header />
      <div className='pages-wrapper'>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App; */
const AppContent = () => {
  //console.log('entered App Content');

  //const roles = useRoles();
  //const roles = ['user.admin'];
  //console.log('roles', roles)
  return (    
      <div className="App">
        <Header />
        <div className='pages-wrapper'>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </div>
      </div>   

  );
};

export const App = () => {
  return (
    <React.Fragment>
      <AuthenticatedEmployeeWrapper>
        <AppContent />
      </AuthenticatedEmployeeWrapper>
    </React.Fragment>
  );
};
