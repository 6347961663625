import { Grid } from "@mui/material";
import CustomTextField from "../../common/CustomTextField/CustomTextField";
import "./FilterFields.css";


const FilterFields = ({ filterFields, handleFilterFieldChange }) => {
    
    return (
        <Grid className="filter-fields-wrapper">
            <Grid item spacing={2} sx={{ marginRight: "2%" }}>
                <CustomTextField
                    id={"Premise"}
                    label={"Premise"}
                    value={filterFields?.Premise}
                    defaultValue={""}
                    onChange={handleFilterFieldChange}
                />

            </Grid>            

            <Grid item spacing={2} sx={{ marginRight: "2%" }}>

                <CustomTextField
                    id={"CreditType"}
                    label={"Credit Type"}
                    value={filterFields?.CreditType}
                    onChange={handleFilterFieldChange}
                />
            </Grid>
        </Grid>
    );

}


export default FilterFields;