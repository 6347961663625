import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import './Spinner.css';

const Spinner = ({ size = 60, color = 'primary', thickness = 4 }) => {
    return (
        <Box sx={{ display: "flex", position: "relative" }}>
            <CircularProgress className="circular-progress" size={size} color={color} thickness={thickness} />
        </Box>
    )
};

export default Spinner;
