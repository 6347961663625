import EditIcon from '@mui/icons-material/Edit';
import {
  GridActionsCellItem,
} from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { validateToken } from "./common/util/AuthManager";
import { config } from './common/util/config';

export const getColumns = (handleAction, type, roles = [], tabIndex) => {
  const showEdit = () => {
    return roles && roles.includes("user.admin");
  }
  const isSortable = true;
  // To Disable Sorting in InProgress, remove true from above and paste the below comment
  //tabIndex === 'two' ? false : true;
  const columns = [
    {
      field: 'action',
      headerName: type === 'withOutStatus' ? 'Edit' : 'View',
      width: 50,
      filterable: false,
      sortable: isSortable,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (type === "withOutStatus") {
          return (
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              color="inherit"
              onClick={(e) => handleAction(e, params.row)}
            />
          )
        } else {
          return (
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              className="textPrimary"
              color="inherit"
              onClick={(e) => handleAction(e, params.row)}
            />

          )
        }
      },
    },
    { field: 'OutageUniqueID', headerName: 'Outage Unique ID', width: 160, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'Premise', headerName: 'Premise', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'ContractAccount', headerName: 'Contract Account', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'formatOutageDate', headerName: 'Outage Date', width: 200, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'formatRestorationDate', headerName: 'Restoration Date', width: 200, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'DurationHrs', headerName: 'Duration Hrs', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'CreditDollar', headerName: 'Credit Dollar', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'CreditType', headerName: 'Credit Type', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'PreviousAmtPaidFREQ', headerName: 'Previous Amt Paid FREQ', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'formatPreviousPostedDateFREQ', headerName: 'Previous Posted Date FREQ', width: 180, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'InstallationNumber', headerName: 'Installation Number', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'AmountCredited', headerName: 'Amount Credited', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'BusinessApproval', headerName: 'Business Approval', width: 120, filterable: true, disableColumnMenu: false, sortable: isSortable },
    { field: 'formatBusinessApprovalDate', headerName: 'Business Approval Date', width: 180, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'BusinessApprovalBy', headerName: 'Business Approval By', width: 120, filterable: false, disableColumnMenu: true, sortable: isSortable },
    { field: 'UniqueId', headerName: 'Unique Id', width: 100, filterable: false, disableColumnMenu: true, sortable: isSortable },
  ];

  if (type === "withOutStatus") {
    return showEdit() ? columns : columns.filter((col) => col.field !== "action");
  } else {
    return columns;
  }
};

export const statusOptions = [{
  id: 2,
  label: "Approved",
  value: "Approved"
},
{
  id: 3,
  label: "Denied",
  value: "Denied"
}]

export const deepEqualObjects = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqualObjects(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}


export const getRequestHeaders = (token, opName = 'get') => {

  if (token) {
    const validToken = validateToken(token);
    if (opName === 'post') {
      return {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
        Authorization: `Bearer ${validToken}`,
        //responseType: "json",
        withCredentials: false
      }
    }
    return {
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
      Authorization: `Bearer ${validToken}`,
      //responseType: "json",
      withCredentials: false
    }
  } else {
    if (opName === 'post') {
      return {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
      }
    }
    return {
      Accept: 'application/json',
      'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
    }
  }

}
