import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Box } from '@mui/material';

function Header() {
    return (
        <AppBar position="static" sx={{ height:"96px", textAlign:"left" }}>
            <Box sx={{ padding: "0% 1.25%", height: "100%"}}>          
                <Toolbar disableGutters sx={{height:"100%"}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            //mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 900,
                            //letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Electric Reliability Credit
                    </Typography>
                    <Toolbar style={{ marginLeft: "34%" }}>
                        <Typography
                            variant="h4"
                            component="a"
                            sx={{
                                //mr: 2,
                                fontFamily: 'monospace',
                                fontWeight: 900,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                alignContent: "center"
                            }}
                        >
                            DTE
                        </Typography>
                    </Toolbar>
                </Toolbar>      
                </Box>       
        </AppBar>
    );
}
export default Header;