// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import {App} from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// reportWebVitals();

import React from "react"
import { createRoot } from "react-dom/client"
import { config } from "./common/util/config"
import { App } from "./App"
import "./index.css"

function updateUrlAfterLogin() {
  // If we came to the page directly, ignore anything in local stroage
  if (!document.referrer) {
    window.sessionStorage.removeItem("urlAfterLogin")
  }

  // If we're coming back after login, attempt to restore the original URL
  const urlAfterLogin = window.sessionStorage.getItem("urlAfterLogin")
  if (urlAfterLogin) {
    window.sessionStorage.removeItem("urlAfterLogin")
    window.history.replaceState({}, "Energy Usage Data", urlAfterLogin)
  }
}
updateUrlAfterLogin()

function showTestHarness() {  
  // Don't show for production
  if (process.env.REACT_APP_ENV === "production") {
    return false
  }

  if (!config.apiSubscriptionKey) {
    return true
  }

  if (window.location.pathname.includes("/test")) {
    console.log('includestest');
    return true
  }

  return false
}

const container = document.getElementById("root")
const root = createRoot(container)

// if (showTestHarness()) {
//   root.render(<TestHarness />)
// } else {
//   root.render(<App />)
// }
root.render(<App />)
