import axios from 'axios';
import { useToken } from '../../contexts/TokenContext/AuthenticatedEmployeeWrapper';
import { config } from './config';
import { useState, useEffect, useRef, useCallback } from 'react';
import memoize from 'fast-memoize';

const axiosConfig = {
    baseURL: config.apiBase,
    headers: {
        Accept: 'application/json',
        'Ocp-Apim-Subscription-Key': config.apiSubscriptionKey,
    },
};

if (!config.isProd) {
    axiosConfig.headers['Ocp-Apim-Trace'] = true;
}

// TODO: may need to re-implement caching
const cachedApis = axios.create({ ...axiosConfig });
//console.log('cachedApis', cachedApis);

// For some calls we don't want any caching behavior
const apis = axios.create(axiosConfig);
//console.log('apis', apis);

function check401(error) {
    // Not a 401
    if (error.response?.status !== 401) {
      return undefined
    }
  
    const response = error?.response?.data
    const code = response?.code
  
    if (["KEY_INVALID", "KEY_NOT_FOUND"].includes(code)) {
      if (!config.isProd) {
        // Redirect to test page to re-enter subscription key
        // eslint-disable-next-line no-alert
        alert("Bad subscription key -- please enter a correct subscription key")
        window.location.pathname = "/test?invalidKey=true"
      }
  
      // Should never get here as long as app config is correct
      return "Bad subscription key"
    } 
    
  }
  
  function check403(error) {
    // Not a 401
    if (error.response?.status !== 403) {
      return undefined
    }
  
    // eslint-disable-next-line no-alert
    alert(
      "Your account doesn't have the correct roles for access to this application"
    )
  
    return "Not Authorized"
  }

  function handleAxiosError(error) {
    // Not an Axios error
    if (!axios.isAxiosError(error)) {
      return
    }
  
    // Ignore if we specifically requested to cancel the request
    if (axios.isCancel(error)) {
      return
    }
  
    // Check for 401 response
    let errorMessage = check401(error)
    if (errorMessage) {
      console.log(errorMessage)
      return
    }
  
    // User doesn't have access
    errorMessage = check403(error)
    if (errorMessage) {
      console.log(errorMessage)
      return
    }
  
    // eslint-disable-next-line no-console
    console.error("Service error: ", error)
  
    // TODO: need better error handling
  
    // eslint-disable-next-line no-alert
    alert(
      "An error occurred retrieving data.  Please refresh the page and try again."
    )
  }

  export function getDefaultRequestConfigWithToken(token) {
    const requestConfig = {
      responseType: "json",
      withCredentials: false
    }
    requestConfig.headers = {}
  
    if (token) {
      requestConfig.headers["Authorization"] = "Bearer " + token
    }
    return requestConfig
  }

  export function getDefaultRequestConfig() {
    const requestConfig = {
      responseType: "json",
      withCredentials: false
    }
    requestConfig.headers = {}
    return requestConfig
  }
  
  export async function jsonPost(url, requestConfig) {
    requestConfig.method = "post"
    // requestConfig.clearCacheEntry = true;
  
    const request = apis.post(url, undefined, requestConfig)
    request.catch(handleAxiosError)
  
    return request
  }
  
  export async function jsonGet(url, requestConfig, cache = true) {
    requestConfig.method = "get"
  
    let request
    if (cache) {
      request = cachedApis.get(url, requestConfig)
    } else {
      request = apis.get(url, requestConfig)
    }
    request.catch(handleAxiosError)
  
    return request
  }

  function _equalJson(valueA, valueB) {
    return JSON.stringify(valueA) === JSON.stringify(valueB)
  }
  export const equalJson = memoize(_equalJson)
  
  export function copyJson(value) {
    if (!value) {
      return undefined
    }
    return JSON.parse(JSON.stringify(value))
  }

  function sameRequest(currentURL, loadingURL, currentConfig, loadingConfig) {
    const sameURL = currentURL === loadingURL
    const sameConfig = equalJson(currentConfig, loadingConfig)
  
    // Already loading this
    return sameURL && sameConfig
  }

  function validRequest(url, config, loadingURL, loadingConfig, forceReload) {
    const currentURL = url;
    const currentConfig = copyJson(config);
    
  
    // Need a valid URL and config
    if (!currentURL || !currentConfig) {
      return [undefined, undefined]
    }
  
    // Double-check config unless we're forcing a reload
    if (!forceReload) {
      // Already loading this
      
      if (sameRequest(currentURL, loadingURL, currentConfig, loadingConfig)) {
        return [undefined, undefined]
      }
    }
    
    return [currentURL, currentConfig]
  }
  
  // React hook for a JSON get
export function useJsonGet(url, requestConfig, cacheRequest = true) {
  
    const [results, setResults] = useState(undefined)
  
    // Start off as true if we haven't loaded anything yet
    const [loading, setLoading] = useState(true)
  
    // Start off as false if we're not forcing a reload
    const [forceReload, setForceReload] = useState(false)
  
    const cache = useRef(cacheRequest)
  
    const mounted = useRef(true)
    useEffect(() => {
      return () => {
        mounted.current = false
      }
    }, [])
  
    const loadingURL = useRef(undefined)
    const loadingConfig = useRef(undefined)
    useEffect(() => {
      if (!mounted.current) {
        return
      }
  
      const [currentURL, currentConfig] = validRequest(
        url,
        requestConfig,
        loadingURL.current,
        loadingConfig.current,
        forceReload
      )
      //console.log('currentURL',currentURL,currentConfig)

      if (!currentURL || !currentConfig) {
        //console.log('22222')
        return
      }
  
      loadingURL.current = currentURL
      loadingConfig.current = currentConfig
      setLoading(true)
  
      // Check for when we finish -- is this still the request data that's wanted?
      const ignoreRequest = () => {
        // No longer mounted
        if (!mounted.current) {
          return true
        }
  
        // Not the most current request
        if (currentURL !== url) {
          return true
        }
  
        if (!forceReload && !equalJson(currentConfig, requestConfig)) {
          return true
        }
  
        // If we get this far, it's the request we wanted
        return false
      }
  
      const fetch = async () => {
        //console.log('fetchhhhhhh')
        // Get the records
        let requestResults = undefined
        try {
          let request
          if (cache) {
            //console.log('cachedapisurl', currentURL, currentConfig);
            request = cachedApis.get(currentURL, currentConfig);
          } else {
            //console.log('apisurl', currentURL, currentConfig);
            request = apis.get(currentURL, currentConfig);
          }
          const response = await request
  
          // Ignore if not the most recent request
          if (ignoreRequest()) {
            return
          }
          requestResults = response.data
        } catch (e) {
          // Error
          handleAxiosError(e)
        }
  
        setResults(requestResults)
        setLoading(false)
      }
      setForceReload(false)
      //console.log('33333333')
      void fetch()
    }, [url, requestConfig, forceReload])
  
    const refresh = useRef(() => {
      setForceReload(true)
    })
  
    return [results, loading, refresh.current]
  }
  
  export function useAuthenticatedJsonGet(urlDetails, cache = true) {
    //console.log('urldetaiuls',urlDetails)
    const token = useToken();
    //console.log('token',token)

    const url = urlDetails?.url || ""
    const requestConfig = getRequestConfig(token, urlDetails, true);
    //console.log('requestConfig',requestConfig)
    //const requestConfig = getRequestConfig(undefined, urlDetails, true)
    //console.log('url for useAuthenticatedJsonGet',url);
    const [results, loading, refresh] = useJsonGet(url, requestConfig, cache)
  //console.log('results loading',results,loading)
    return [results, loading, refresh]
  }

  function getRequestConfig(token, urlDetails, authenticated) {
    if (!urlDetails) {
      return undefined
    }
  
    try {
      let newConfig
      if (authenticated) {
        const validToken = validateToken(token)
        newConfig = getDefaultRequestConfigWithToken(validToken)
      } else {
        newConfig = getDefaultRequestConfig()
      }
  
      newConfig.method = "get"
      if (urlDetails.params) {
        newConfig.params = { ...urlDetails.params }
      }
      return newConfig
    } catch {
      // invalid parameters
    }
  
    return undefined
  }

  export function validateToken(token) {
    if (!token) {
        throw new Error('missing token');
    }

    if (token.length < 100) {
        throw new Error('invalid token');
    }

    return token;
}


async function authenticatedPost(token, urlDetails, data) {
  const url = urlDetails?.url || ""

  const requestConfig = getRequestConfig(token, urlDetails, true)
  requestConfig.method = "post"

  const request = apis.post(url, data, requestConfig)
  request.catch(handleAxiosError)

  const response = await request
  return response?.data
}

export function useAuthenticatedJsonPost(urlDetails, data) {
  const token = useToken()

  const post = useCallback(async () => {
    return authenticatedPost(token, urlDetails, data)
  }, [token, urlDetails, data])

  return [post]
}

export function useUnauthenticatedJsonGet(urlDetails, cache = true) {  
  const url = urlDetails?.url || ""
  console.log('Entered useUnauthenticatedJsonGet',url);
  const requestConfig = getRequestConfig(undefined, urlDetails, false)
  const [results, loading, refresh] = useJsonGet(url, requestConfig, cache)

  return [results, loading, refresh]
}