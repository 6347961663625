import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Grid } from "@mui/material";
import TextField from '@mui/material/TextField';
import './DateTimePickerComponent.css';


const DateTimePickerComponent = ({ startDateTime, endDateTime, handleStartDateTimeChange, handleEndDateTimeChange }) => {
    return (
        
            <Grid sx={{ display: "flex", justifyContent: "space-between", marginRight: "2%" }}>
                <Grid item className="mui-date-picker" sx={{ marginRight: "2%" }}>
                    <label htmlFor="dateTimePicker">Outage Start Date Time:</label>
                    <DatePicker
                        className= "custom-DatePicker"
                        selected={startDateTime}
                        onChange={handleStartDateTimeChange}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="MM/dd/yyyy HH:mm:ss"
                        placeholderText="MM/dd/yyyy HH:mm:ss"
                    />
                </Grid>
                <Grid item className="mui-date-picker">
                    <label htmlFor="dateTimePicker">Outage End Date Time:</label>
                    <DatePicker
                    className= "custom-DatePicker"
                        selected={endDateTime}
                        onChange={handleEndDateTimeChange}
                        showTimeSelect
                        timeFormat="HH:mm:ss"
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="MM/dd/yyyy HH:mm:ss"
                        placeholderText="MM/dd/yyyy HH:mm:ss"
                    />
                </Grid>               
                
            </Grid>
       

    );
};

export default DateTimePickerComponent;
