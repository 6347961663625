import React, { useState, useEffect } from 'react';
import { Typography, Box, Grid } from "@mui/material";
import Spinner from '../../common/Spinner/Spinner';
import axios from 'axios';
import { config } from '../../common/util/config';
import './Summary.css';
import { useAuthenticatedJsonGet } from '../../common/util/AuthManager'
import { useJsonEqualState } from '../../hooks/useJsonEqualState';
import { useToken } from "../../contexts/TokenContext/AuthenticatedEmployeeWrapper";
import { validateToken } from "../../common/util/AuthManager";
import { getRequestHeaders } from '../../utils';

const Summary = ({ tableData }) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [summaryData, setSummaryData] = useState({

        NumberofOutagesApprovedToday: "",
        NumberofOutagesEligibleforFrequency: "",
        NumberofCreditIssuedOutages: "",
        NumberofOutagesApprovedYesterday: "",
        NumberofOutagesEligibleforDuration: "",
        NumberofOutagesApproved: "",
        NumberofOutagesPendingReviewforFrequency: "",
        TotalNumberofOutagesEligibleforCredits: "",
        NumberofOutagesPendingReview: "",
        NumberofOutagesPendingReviewforDuration: "",
        NumberofOutagesDenied: "",
        NumberofOutagesApprovedinLast30Days: ""
    });
    
    function getURL() {
        return {
            url: `${config.apiBase}/GetCreditSummaryInfo`,
        };
    }
    const url = getURL()?.url;
    const [token, usrName] = useToken();

    useEffect(() => {
        const getSummaryData = async () => {
            setLoading(true);
            console.log('api base value', `${config.apiBase}`);
            console.log('webEnv Value', `${config.webEnv}`);
            
            try {                
                const response = await axios.get(url, {
                    headers: getRequestHeaders(token)
                });                
                setSummaryData(response.data);
            } catch (error) {                
                setError(error);
            }
            setLoading(false);
        }
        getSummaryData();
    }, [])
    return (
        <Box className="status-count">
            {loading ? <Spinner /> : <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Approved Today"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            {/* <Typography variant="body1"><span>{countWithEmptyStatus}</span></Typography> */}
                            {/* <Typography variant="body1"><span>20</span></Typography> */}
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesApprovedToday}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Eligible for Frequency"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            {/* <Typography variant="body1"><span>{countWithStatus}</span></Typography> */}
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesEligibleforFrequency}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Credit Issued Outages"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofCreditIssuedOutages}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Approved Yesterday"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesApprovedYesterday}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Eligible for Duration"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesEligibleforDuration}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Approved"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesApproved}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Pending Review for Frequency"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesPendingReviewforFrequency}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Total Number of Outages Eligible for Credits"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.TotalNumberofOutagesEligibleforCredits}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Pending Review"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesPendingReview}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Pending Review for Duration"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesPendingReviewforDuration}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Denied"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesDenied}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item>
                    <Grid container sx={{ display: 'flex' }}>
                        <Grid item xs={7}>
                            <Typography variant="body1"><span className='summaryKey'>{"Number of Outages Approved in Last 30 Days"}</span>:</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="body1"><span style={{ fontWeight: "700", color: "black" }} className='summaryKey'>{summaryData?.NumberofOutagesApprovedinLast30Days}</span></Typography>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>}
        </Box>

    );
}

export default Summary;

