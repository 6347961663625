import 'react-datepicker/dist/react-datepicker.css';
import { Grid, Stack } from "@mui/material";
import TextField from '@mui/material/TextField';



const CustomTextField = ({ id, label, value, onChange }) => {

    return (
        
            <Stack spacing={2}>
                <Grid item className="mui-date-picker" spacing={2} sx={{ marginRight: "2%" }}>
                    <label htmlFor={id}>{label}</label>
                    <TextField
                        id={id}
                        value={value}
                        defaultValue={""}
                        onChange={onChange}
                        inputProps={{autoComplete: 'off'}}
                    />
                </Grid>
                </Stack>       

    );
};

export default CustomTextField;
