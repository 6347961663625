import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function DropDownComponent({ id,labelId,label, value, onChange, options,required=false,helperText="", ...selectProps }) {

    const handleChange = (event) => {
       onChange(id,event.target.value);
     };

    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120,width:"100%" }}>
                 {label && <InputLabel id={id}>{label}</InputLabel>}
                <Select
                    labelId={labelId}
                    id={id}
                    value={value}
                    label={label}
                    onChange={handleChange}
                    {...selectProps}
                >
                    <MenuItem value="1">
                        <em>{""}</em>
                    </MenuItem>
                    {options.map(option => (
                        <MenuItem key={option.id} value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        </div>
    );
}

export default DropDownComponent;
