import React, { useState, useEffect } from 'react';
import { Button, Grid, Box } from "@mui/material";
import CustomDialog from "../../common/CustomDialog/CustomDialog";
import {config} from '../../common/util/config';
import {getRequestHeaders} from '../../utils';
import axios from 'axios';
import {useToken} from "../../contexts/TokenContext/AuthenticatedEmployeeWrapper";

const ActionButtons = ({ tableData, selectedRows, getTableData }) => {
    //console.log('ActionButtons', selectedRows);
    const count = selectedRows && selectedRows.length > 0 ? selectedRows.length : 0;
    const disableButtons = count > 0 ? false : true; 
    const [showModal, setShowModal] = useState(false);
    const [selectedStatusButton, setSelectedStatusButton] = useState(null);
    const [error, setError] = useState(null);
    const [selectedRecordsCount, setSelectedRecordsCount] = useState(count);
    const [disabled, setDisabled] = useState(disableButtons);
    const [token, usrName] = useToken();
    const [isConfirmationModalLoading, setIsConfirmationModalLoading] = useState(false);

    useEffect(() => {       
        //console.log('count useeffect',selectedRows)
        const updatedCount = selectedRows && selectedRows.length > 0 ? selectedRows.length : 0;
        let disabled = true;
        if (updatedCount) {
            disabled = false;
        }
        setSelectedRecordsCount(updatedCount);
        setDisabled(disabled);
    }, [selectedRows])
    const handleStatus = (e) => {
        //console.log('selectedRows', selectedRows, tableData);
        const selectedData = tableData.filter((data) => selectedRows.some((UniqueId) => UniqueId === data.UniqueId));
        const hasValue = selectedData.some((row) => row.BusinessApproval);
        if (hasValue) {
            alert("It already has BusinessApproval as Approved or Denied. Please select rows without BusinessApproval.")
        } else {
            const updatedCount = selectedRows && selectedRows.length > 0 ? selectedRows.length : 0;
            setSelectedRecordsCount(updatedCount);
            setShowModal(true);
            if (e.target.id === "approve")
                setSelectedStatusButton("APPROVED");
            else
                setSelectedStatusButton("Denied");
        }
    }
    const handleModalClose = () => {
        setShowModal(false);
    }
    const handleStatusSave = async () => {        
        const payload = selectedRows.map((row) => {
            return {
                UniqueId: row,
                BusinessApproval: selectedStatusButton,
                BusinessApprovalBy: usrName
            }
        });
        
        try {
            setIsConfirmationModalLoading(true);
            //Add actual api url for saving edit data 
            //const response = await axios.post('https://dte-eda-erc-lab-fnapp.azurewebsites.net/api/PostCreditSummary?code=UpN_XkDJwyaRdZ7MYmWHMP4WthbmeNWdPYMC4fQEv5SxAzFuEd5InA==', payload);
            const response = await axios.post(`${config.apiBase}/PostCreditSummary`, payload
                    , {
                        headers: getRequestHeaders(token,'post')                        
                    }
                );
            setIsConfirmationModalLoading(false);
            getTableData('withOutStatus');
            setDisabled(true);            
            setShowModal(false);            
        } catch (error) {
            setIsConfirmationModalLoading(false);
            setShowModal(false);
            setError(error);
            //add alert here
        }

    }
    let displayText = "";
    //console.log('selectedStatusButton', selectedStatusButton);
    if (selectedStatusButton === "APPROVED") {
        displayText = `Are you sure you want to approve ${selectedRecordsCount} records?`;
    } else if (selectedStatusButton === "Denied") {
        displayText = `Are you sure you want to deny ${selectedRecordsCount} records?`;
    }

    const title = "Status Update";
    return (
        <Box className="home-wrapper" sx={{ margin: "1% 0%", padding: "0% 1%" }}>
            <Grid container sx={{ display: "flex", marginBottom: 2 }} spacing={2}>
                <Grid item>
                    <Button autoFocus id="approve" variant="outlined" disabled={disabled} onClick={handleStatus}>
                        Approve
                    </Button>
                </Grid>
                <Grid item>
                    <Button autoFocus id="deny" variant="outlined" disabled={disabled} onClick={handleStatus}>
                        Deny
                    </Button>
                </Grid>
            </Grid>
            {showModal && <CustomDialog displayText={displayText} isConfirmationModalLoading={isConfirmationModalLoading} title={title} open={showModal} handleSave={handleStatusSave} handleModalClose={handleModalClose} />}
        </Box>
    );
}

export default ActionButtons;
