import React from "react"
//import { Row, Col } from "react-bootstrap"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
//import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { Container, Grid,CircularProgress } from "@mui/material";
import "./Spinner.css"

function _Spinner(props) {
  const size = props.size || "2x"

  let className = "spinner"
  if (props.className) {
    className += " " + props.className
  }

  // return (
  //   <React.Fragment>
  //     <Row className={className}>
  //       <Col className="text-center">
  //         <FontAwesomeIcon
  //           icon={faSpinner}
  //           spin={true}
  //           title="Loading"
  //           size={size}
  //         />
  //       </Col>
  //     </Row>
  //   </React.Fragment>
  // )
  return (
    <Container>
        <Grid container className={className} spacing={2}>
            <Grid item className="text-center" xs={12}>
            <CircularProgress size={size} title="Loading" />
            </Grid>
        </Grid>
    </Container>
)
}
export const Spinner = React.memo(_Spinner)
