import { config } from './config';

// Production config
let clientId = '92964f77-37df-4a8b-a79e-e9de47c0d296';
let tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
let redirectUri = 'https://erc.sites.dteco.com';

if (!config.isProd) {
    // Test config
    clientId = 'fa0fedc3-ce59-4bc8-a5f3-5d8b0a6c2dcc';
    tenantId = '8e61d5fe-7749-4e76-88ee-6d8799ae8143';
    redirectUri = 'https://' + window?.location?.hostname;
}

// TODO: separate config for other environments

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
      clientId: clientId,
      authority: "https://login.microsoftonline.com/" + tenantId,
      redirectUri: redirectUri
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false
    }
  }

export const loginRequest = {
    scopes: ['openid'],
    extraQueryParameters: { domain_hint: 'dteco.com' },
};
