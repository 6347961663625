import * as React from 'react';
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Spinner from '../Spinner/Spinner';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DialogComponent({ open, disableActionButtons, handleClose, handleSave, children, title, loading=false }) {
    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ margin: "0 8px", p: 2 }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {children}
                </DialogContent>
                {!disableActionButtons && <DialogActions>
                    <Grid container sx={{ display: "flex", justifyContent: "flex-end" }} spacing={2}>
                        <Grid item>
                            <Button autoFocus variant="outlined" onClick={handleClose}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button autoFocus variant="contained" disabled={loading} onClick={handleSave}>
                                {loading ? "Loading..." : "Save"}
                            </Button>
                            {/* <LoadingButton loading={loading} loadingPosition="start" variant="contained">Save</LoadingButton> */}
                        </Grid>
                    </Grid>
                </DialogActions>}
            </BootstrapDialog>
        </React.Fragment>
    );
}