import React, { useEffect, useState } from "react";
//import { Container, Row, Col } from 'react-bootstrap';
import { Container, Grid } from "@mui/material";
import {
  MsalProvider,
  MsalAuthenticationTemplate,
  useMsal,
  MsalAuthenticationResult,
  useAccount,
  IMsalContext,
} from "@azure/msal-react";
import {
  SilentRequest,
  InteractionType,
  PublicClientApplication,
  AuthenticationResult,
  InteractionStatus,
} from "@azure/msal-browser";
import { msalConfig } from "../../common/util/employeeAuthConfig";
import { Spinner } from "../../components/Spinner/Spinner";
import { loginRequest } from "../../common/util/employeeAuthConfig";
import { useJsonEqualState } from "../../hooks/useJsonEqualState";
import { isGoodToken } from "./isGoodToken";
//import {AppConfigWrapper} from "../AppConfig/Wrapper";

function useAuthenticationResult() {
  // Attemp silent login first
  const { accounts, instance, inProgress } = useMsal();
  const account = useAccount(accounts?.[0] || null);
  const [result, setResult] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      // Login in progress
      return;
    }

    if (!account) {
      return;
    }

    const tokenRequest = {
      scopes: ["openid"],
      account: account,
      forceRefresh: forceRefresh,
    };
    //console.log('tokenrequest',tokenRequest)
    instance
      .acquireTokenSilent(tokenRequest)
      .then((newResult) => {
        //console.log('newResult',newResult)
        // Force a refresh we if the token is no good or about to expire
        if (isGoodToken(newResult?.idToken)) {
          setResult(newResult);
          setForceRefresh(false);
        } else {
          setResult(undefined);
          setForceRefresh(true);
        }
      })
      .catch((e) => {
        console.error(e);

        // If token failed, try reloading to trigger another login
        void instance.acquireTokenRedirect(loginRequest);
      });
  }, [account, inProgress, instance, forceRefresh]);

  return result;
}

const msalInstance = new PublicClientApplication(msalConfig);

const LoginError = (error) => {
  let message = "Error signing in";
  console.error("Login error: ", error);
  if (error.error?.message?.includes("AADSTS50105")) {
    message = "Your account does not have access to this application.";
  }

  return (
    <Container className="bodyContent">
      <Grid container className="error p-3">
        <Grid item xs={12}>
          {message}
        </Grid>
      </Grid>
    </Container>
  );
};

const LoginInProgress = (props) => {
  // TODO: does this need more?
  return <Spinner />;
};

function isLocalhost() {
  const hostname = window?.location?.hostname || "";
  return (
    hostname.indexOf("localhost") !== -1 || hostname.indexOf("127.0.0.1") !== -1
  );
}

export function useRoles() {
  const result = useAuthenticationResult();
  const [roles, setRoles] = useJsonEqualState([]);

  useEffect(() => {
    const claims = result?.idTokenClaims;
    const newRoles = claims?.roles || [];
    setRoles(newRoles);
  }, [result, setRoles]);

  return roles;
}

export function useToken() {
  const result = useAuthenticationResult();  
  const [token, setToken] = useState(undefined);  
  useEffect(() => {
    const newToken = result?.idToken || undefined;
    setToken(newToken);
  }, [result, setToken]);  
  return [token, result?.account.username];
}


export const AuthenticatedEmployeeWrapper = (props) => {
  //console.log('entered AuthenticatedEmployeeWrapper',msalInstance);
  if (!msalInstance) {
    return null;
  }

  if (isLocalhost()) {
    //console.log('entered local');
    window.location.href = "https://lab.erc.sites.dteco.com/";
    //window.location.href = "http://localhost:3000/";
    return null;
  }
//console.log('valuesssssssssss',InteractionType.Redirect,msalInstance)
//console.log('login requestsss',loginRequest)
//console.log('LoginError',LoginError)
//console.log('LoginInProgress',LoginInProgress)

  return (    
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
        errorComponent={LoginError}
        loadingComponent={LoginInProgress}
        >
          {props.children}
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};
