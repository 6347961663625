import memoize from 'fast-memoize';

export function midpoint(start, end) {
    return Math.round((start + end) / 2);
}

function _formatDigits(digits) {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
        useGrouping: true,
    });
}
export const formatDigits = memoize(_formatDigits);

export const costFormat = formatDigits(2);

export function roundByRange(dataValue, range) {
    let rounded = Math.round(dataValue * 100) / 100;

    if (rounded && range < 2) {
        return formatDigits(2).format(dataValue);
    }

    rounded = Math.round(dataValue * 10) / 10;
    if (!rounded || range > 10) {
        return formatDigits(0).format(dataValue);
    }

    return formatDigits(1).format(dataValue);
}

export function roundCostByRange(dataValue, range) {
    if (range < 40) {
        return formatDigits(2).format(dataValue);
    }

    return formatDigits(0).format(dataValue);
}

export function createUUID() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
}

export function notNullOrUndefined(value) {
    return value !== null && value !== undefined;
}

export function uniqueValues(values){
    return values.filter((item, index) => values.indexOf(item) === index);
}

/**
 * JSON stringify for 2 values are equal
 *
 * @param valueA
 * @param valueB
 */
function _equalJson(valueA, valueB) {
    return JSON.stringify(valueA) === JSON.stringify(valueB);
}
export const equalJson = memoize(_equalJson);

export function copyJson(value) {
    if (!value) {
        return undefined;
    }
    return JSON.parse(JSON.stringify(value));
}

export function capitalize(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function normalizeValue(value) {
    if (!value) {
        return undefined;
    }

    const trimmed = value.trim();
    if (!trimmed) {
        return undefined;
    }

    return trimmed;
}

export function formatPhoneNumber(value) {
    const validated = normalizeValue(value);
    if (!validated) {
        return undefined;
    }

    // TODO: handle more than just 10 digit numbers
    if (validated.length > 10) {
        return validated;
    }

    const areaCode = validated.substring(0, 3);
    const prefix = validated.substring(3, 6);
    const suffix = validated.substring(6);

    return `(${areaCode}) ${prefix}-${suffix}`;
}

export function getObject(data) {
    return data || {};
}

export function getArray(data) {
    return data || [];
}
