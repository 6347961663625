import { useCallback, useState } from "react"
import { equalJson } from "../common/util/utils"

export function useJsonEqualState(initialValue) {
  const [value, setValue] = useState(initialValue)

  const updateValue = useCallback(
    newValue => {
      // Only update the underlying value if it's actually different
      if (equalJson(value, newValue)) {
        return
      }
      setValue(newValue)
    },
    [value]
  )

  return [value, updateValue]
}
